@import "../../scss/variables.scss";

.navbar-link:not(.is-arrowless)::after {
  border-color: #00947e;
}

.navbar {
  width: 100vw !important;
}

.navbar.scrolled {
  @include nav-shadow();
  @include fast-transition();
  padding: 0.25rem 0.5rem;
  box-shadow: inset 0 -4px 5px 0px rgba(0, 0, 0, 0.1),
    0 0px 15px 4px rgba(0, 0, 0, 0.15);
}

.navbar.simple {
  @include fast-transition();
  padding: 0.6rem 0.5rem;
}

.logo {
  width: 45px !important;
  height: 100px !important;
}

.navbar-link:hover,
.navbar-item:hover {
  color: $green-color-dark !important;
}

@media screen and (max-width: 1023px) {
  .navbar.scrolled {
    box-shadow: none;
    padding: 0;
  }

  .navbar.simple {
    padding: 0;
  }
}

.conditional-buttons {
  border: none !important;
}

.conditional-buttons:active {
  border: none !important;
}

.conditional-buttons:focus {
  border: none !important;
}

.conditional-buttons:focus-within {
  border: none !important;
}
