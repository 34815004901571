@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

$header-font: "Fredoka", sans-serif;
$text-font: "Montserrat", sans-serif;
$cinzel-font: "Cinzel Decorative", cursive;
$great-vibes-font: "Great Vibes", cursive;

$header-font-weight: 700;
$text-font-weight: 400;

$green-color-light: #ace9ac;
$green-color-dark: #5c775c;
$pista-light: #9bb39d;
$pista-dark: #063f0b;
$dark-color: #000000;
$gray-color: #d9d9d9;

$yellow-light: rgb(252, 201, 12);
$yellow-dark: rgba(214, 143, 9, 1);

$h1-font-size: 2rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.6rem;
$h4-font-size: 1.4rem;
$h5-font-size: 1.2rem;
$normal-font-size: 1rem;

$normal-letter-spacing: 0.5px;

$shadow-color: rgba(156, 147, 147, 0.52);
@mixin slow-transition {
  transition: all 0.5s ease-in-out;
}

@mixin fast-transition {
  transition: all 0.3s ease-in-out;
}

@mixin small-shadow {
  box-shadow: 1px 0px 10px 4px $shadow-color;
  -webkit-box-shadow: 1px 0px 10px 4px $shadow-color;
  -moz-box-shadow: 1px 0px 10px 4px $shadow-color;
}

@mixin large-shadow {
  box-shadow: 1px 0px 10px 10px $shadow-color;
  -webkit-box-shadow: 1px 0px 10px 10px $shadow-color;
  -moz-box-shadow: 1px 0px 10px 10px $shadow-color;
}

@mixin nav-shadow {
  box-shadow: 0 0 5px 3px #{$dark-color + "33"};
  -webkit-box-shadow: 0 0 5px 3px #{$dark-color + "33"};
  -moz-box-shadow: 0 0 5px 3px #{$dark-color + "33"};
}
