@import "../../scss/variables.scss";

.wrapper {
  width: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $green-color-dark;
  // background-image: url("../../images/login-bg.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.login-container {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-left: 0.25px solid rgba(255, 255, 255, 0.5);
  border-top: 0.25px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(12px);
  border-radius: 30px;
}

@media screen and (max-width: 767px) {
  .wrapper {
    // background-position-x: 10%;
  }
  .login-container {
    width: 100%;
    padding: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .login-container {
    width: 70%;
    padding: 3rem;
  }
}

@media screen and (min-width: 1366px) {
  .login-container {
    width: 40%;
    padding: 4rem 5rem;
  }
}

.icon {
  color: black !important;
}
