@import "../../node_modules/bulma/bulma.sass";
@import "variables.scss";

html {
  margin: 0;
  // -ms-overflow-style: none !important;
  // scrollbar-width: none !important;
}

html::-webkit-scrollbar {
  display: none !important;
}

body {
  font-family: $text-font;
  font-weight: $text-font-weight;
  letter-spacing: $normal-letter-spacing;
  margin: 0;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

@mixin common-header {
  font-family: $header-font;
  font-weight: $header-font-weight;
}

@mixin common-text {
  font-family: $text-font;
  font-weight: $text-font-weight;
}

.font-cinzel {
  font-family: $cinzel-font;
}

h1,
.h1 {
  @include common-header();
  font-size: $h1-font-size;
}

h2,
.h2 {
  @include common-header();
  font-size: $h2-font-size;
}

h3,
.h3 {
  @include common-header();
  font-size: $h3-font-size;
}

h4,
.h4 {
  @include common-header();
  font-size: $h4-font-size;
}

h5,
.h5 {
  @include common-header();
  font-size: $h5-font-size;
}

h6,
.h6 {
  @include common-header();
  font-size: $normal-font-size;
}

p,
.p {
  @include common-text();
}

a,
.a {
  @include common-text();
  font-weight: 500;
}

button,
.button {
  @include common-text();
  font-weight: 500;
}

.has-background-green {
  background-color: $green-color-light;
}

.has-background-green-dark {
  background-color: $green-color-dark;
}

.has-text-green {
  color: $green-color-light !important;
}

.has-text-green-dark {
  color: $green-color-dark !important;
}

.yellow-gradient-textbox {
  background: $yellow-light;
  background: linear-gradient(90deg, $yellow-light 0%, $yellow-dark 100%);
  outline: none;
  border: none;
  border-radius: 20px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
}

.yellow-gradient-textbox:focus {
  outline: none;
  border: none;
}

.yellow-gradient-button {
  background: $yellow-light;
  background: linear-gradient(90deg, $yellow-light 0%, $yellow-dark 100%);
  outline: none;
  border: none;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
}

.h-100 {
  height: 100% !important;
}

.footer-link {
  color: $green-color-dark !important;
}

body::-webkit-scrollbar {
  width: 0;
}

.has-text-gray {
  color: $gray-color !important;
}

.has-background-gray {
  background-color: $gray-color !important;
}

.great-vibes-font {
  font-family: $great-vibes-font !important;
}
